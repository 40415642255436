@use "../../variables";

// default slider with ume-slider class
.mat-mdc-slider, .mdc-slider {
  &.ume-slider {
    .mat-ripple {
      width: 0 !important;
      height: 0 !important;
    }

    .mdc-slider__thumb-knob {
      background-color: variables.$red !important;
      border-color: variables.$red !important;
    }

    .mdc-slider__track {
      &--active {
        height: 2px !important;

        &_fill {
          background-color: variables.$blue !important;
          border-color: variables.$blue !important;
        }
      }

      &--inactive {
        height: 2px !important;
        background-color: variables.$gray-darker !important;
      }
    }

    .mdc-slider__thumb-knob {
      width: 16px !important;
      height: 16px !important;
      border-width: initial !important;
      top: 47% !important;
    }
  }

  &.wide-slider {
    display: flex;
    flex: 1 1 auto;
  }
}

//range-slider with ume-range-slider class

// .mat-mdc-slider, .mdc-slider {
//   &.ume-range-slider {
//     .mat-ripple {
//       width: 0 !important;
//       height: 0 !important;
//     }
//     .mdc-slider__thumb-knob {
//        background-color: $red !important ;
//        border-color: $red !important ;
//      }
//      .mdc-slider__track {
//         &--active {
//           height: 2px !important;
//           &_fill {
//             background-color: $yellow !important;
//             border-color: $yellow !important;
//           }
//         }

//         &--inactive {
//           height: 2px !important;
//           background-color: $gray-darker !important;
//         }

//         .mdc-slider__tick-marks {
//           display: none;
//         }
//      }
//   }}
