@use '../../variables';

.mat-accordion {
  &.files-accordion {
    & + & {
      margin-top: variables.$content-gap;
    }
  }
}

.mat-expansion-panel {
  padding: 0 0 variables.$content-gap-half;
  box-shadow: none;
  background-color: transparent;
}

.mat-expansion-panel-header {
  position: relative;

  &.light-gray {
    background-color: variables.$gray-lighter-200;

    &.mat-expanded {
      &:focus,
      &:hover {
        background-color: variables.$gray-lighter-200;
      }
    }
  }

  &:before {
    background-color: variables.$border-color;
    content: '';
    position: absolute;
    left: 24px;
    bottom: 0;
    width: 100%;
    height: 1px;
  }

  //&.mat-expanded {
  //  &:before {
  //    bottom: 12px;
  //  }
  //}
}

.mat-expansion-panel-header-title {
  font-size: variables.$fs-md;
  font-weight: 500;
}

.mat-expansion-panel-header-description {
  justify-content: flex-end;
  align-items: center;
}

.request-all {
  margin-right: variables.$content-gap;
}

.mat-expansion-panel-body {
  padding: 0 variables.$content-gap-md variables.$content-gap;
}
