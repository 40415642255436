@use '../../variables';

.mat-mdc-table {
  &.w-100 {
    width: 100%;
  }

  .mat-mdc-row {
    &:hover,
    &:active {
      background-color: whitesmoke;
    }

    &.selected-row {
      background-color: whitesmoke;
    }
  }

  &.first-cell {
    td.mat-mdc-cell:first-of-type,
    td.mat-mdc-footer-cell:first-of-type,
    th.mat-mdc-header-cell:first-of-type {
      padding-left: 0;
    }
  }

  &.cells-padded {
    .mat-mdc-row,
    .mat-mdc-header-row {
      td.mat-mdc-cell,
      .mat-mdc-header-cell {
        padding-left: variables.$content-gap-half;
        padding-right: variables.$content-gap-half;
      }
    }

    td.mat-mdc-cell:first-of-type,
    td.mat-mdc-footer-cell:first-of-type,
    th.mat-mdc-header-cell:first-of-type {
      padding-left: variables.$content-gap;
    }

    td.mat-mdc-cell:last-of-type,
    td.mat-mdc-footer-cell:last-of-type,
    th.mat-mdc-header-cell:last-of-type {
      padding-right: variables.$content-gap;
    }
  }

  td.mat-mdc-cell {
    vertical-align: middle;

    &.ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.row-h-40 {
    .mat-mdc-row {
      height: 40px;
    }
  }

  &.row-h-32 {
    .mat-mdc-row {
      height: 32px;
    }
  }

  &.questionnaire-table {
    mat-row,
    mat-header-row,
    mat-footer-row,
    th.mat-mdc-header-cell,
    td.mat-mdc-cell,
    td.mat-mdc-footer-cell {
      border-bottom-color: transparent;
    }

    td.mat-mdc-cell {
      padding-top: variables.$content-gap-half;
      padding-bottom: variables.$content-gap-half;
    }
  }
}

table.mat-mdc-table {
  border-spacing: 0;

  tr.mat-header-row {
    height: 48px;
    box-shadow:
      0 2px 2px rgba(0, 0, 0, 0.24),
      0 0 2px rgba(0, 0, 0, 0.12);
    z-index: 1;
  }

  tr.mat-mdc-row,
  tr.mat-mdc-footer-row {
    height: 48px;
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: variables.$content-gap;
  }

  [dir='rtl'] th.mat-mdc-header-cell:first-of-type,
  [dir='rtl'] td.mat-mdc-cell:first-of-type,
  [dir='rtl'] td.mat-mdc-footer-cell:first-of-type {
    padding-left: 0;
    padding-right: variables.$content-gap;
  }

  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: variables.$content-gap;
  }

  [dir='rtl'] th.mat-mdc-header-cell:last-of-type,
  [dir='rtl'] td.mat-mdc-cell:last-of-type,
  [dir='rtl'] td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0;
    padding-left: variables.$content-gap;
  }
}

th.mat-mdc-header-cell {
  text-align: left;
  vertical-align: middle;
}

[dir='rtl'] th.mat-mdc-header-cell {
  text-align: right;
}

th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
