@use "sass:map";
@use "../variables";
@use "../pallete";

.tabs-like {
  ul {
    display: flex;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    font-weight: 500;
    font-size: variables.$body-fs;
    line-height: 1.2;
    list-style: none;
    text-align: center;
    letter-spacing: 0.75px;
    overflow: hidden;

    a,
    .link-placeholder {
      display: block;
      color: variables.$gray;
      padding: variables.$content-gap;
      text-decoration: none;
      transition-property: background-color, color;
      transition-duration: variables.$ume-transition-duration;
      transition-timing-function: variables.$ume-transition-tf;

      &:after {
        background-color: map.get(variables.$ume-color-palette, "blue-600");
        content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        left: 100%;
        bottom: 0;
        transition-property: left;
        transition-duration: variables.$ume-transition-duration;
        transition-timing-function: variables.$ume-transition-tf;
      }
    }

    a {
      &:hover,
      &:focus,
      &.active {
        background-color: map.get(variables.$ume-color-palette, "blue-100");
        color: map.get(variables.$ume-color-palette, "blue-600");

        &:after{
          left: 0;
        }
      }

      &.active:hover {
        color: map.get(pallete.$ume-grey-palette, 900);
      }

      &.disabled {
        pointer-events: none;
      }
    }
  }

  .link-placeholder {
    opacity: .5;
  }
}

.mat-tab-link {
  opacity: 1 !important;
}

.disabled {
  opacity: .5 !important;
}

