@use '../../variables';

.mat-mdc-card,
.mdc-card {
  padding: variables.$content-gap;

  .mat-mdc-card-title {
    font-size: variables.$h2-font-size;
    font-weight: variables.$headings-font-weight;
    margin-bottom: variables.$content-gap-half;
  }

  .mat-mdc-card-content {
    padding: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  & .mat-mdc-card-subtitle {
    &:empty {
      display: none;
    }
  }

  & .mat-mdc-card-actions,
  & .mat-mdc-card-footer {
    &:empty {
      display: none;
    }
  }
}
