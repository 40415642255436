@use 'sass:math';
@use "sass:map";
@use 'pallete';

// Colors
$white: rgb(255, 255, 255) !default;
$black: rgb(0, 0, 0) !default;
$blue-lightest: map.get(pallete.$ume-blue-palette, 50) !default; // rgb(230, 246, 252) !default;
$blue-light: map.get(pallete.$ume-blue-palette, 200) !default;
$blue-dark: map.get(pallete.$ume-blue-palette, 800) !default;
$blue: map.get(pallete.$ume-blue-palette, 700) !default;
$green: map.get(pallete.$ume-green-palette, 600) !default; // rgb(39, 174, 96) !default;
$yellow: map.get(pallete.$ume-yellow-palette, 400) !default; // #f3c703 !default;
$orange: map.get(pallete.$ume-yellow-palette, 900) !default; // rgb(247, 154, 14) !default;
$red: map.get(pallete.$ume-red-palette, 700) !default; // rgb(214, 16, 67) ;

$teal: rgb(3, 171, 160) !default;
$pink: rgb(255, 91, 119) !default;
$pink-light: rgb(241 158 194) !default;
$purple: rgb(112, 48, 160) !default;

$gray-darker: map.get(pallete.$ume-grey-palette, 900) !default;
$gray-dark: map.get(pallete.$ume-grey-palette, 800) !default;
$gray: map.get(pallete.$ume-grey-palette, 700) !default;
$gray-light: map.get(pallete.$ume-grey-palette, 600) !default;
$gray-lighter: map.get(pallete.$ume-grey-palette, 400) !default;
$gray-lighter-300: map.get(pallete.$ume-grey-palette, 300) !default;
$gray-lighter-200: map.get(pallete.$ume-grey-palette, 200) !default;
$gray-lightest: map.get(pallete.$ume-grey-palette, 100) !default;
$gray-lightest-bg: map.get(pallete.$ume-grey-palette, 50) !default;

// Theme colors
$ume-color-palette: (
  'white': $white,
  'grey-900': $gray-darker,
  'grey-800': $gray-dark,
  'grey-700': $gray,
  'grey-600': $gray-light,
  'grey-400': $gray-lighter,
  'grey-300': map.get(pallete.$ume-grey-palette, 300),
  'grey-200': map.get(pallete.$ume-grey-palette, 200),
  'grey-100': $gray-lightest,
  'grey-50': map.get(pallete.$ume-grey-palette, 50),
  'red-500': $red,
  'orange-500': $orange,
  'yellow-500': $yellow,
  'green-500': $green,
  'green-400': $teal,
  'blue-100': $blue-lightest,
  'blue-300': map.get(pallete.$ume-blue-palette, 300),
  'blue-400': $blue-light,
  'blue-500': map.get(pallete.$ume-blue-palette, 500),
  'blue-600': map.get(pallete.$ume-blue-palette, 600),
  'blue-700': rgb(31, 72, 124),
  'blue-800': map.get(pallete.$ume-blue-palette, 800),
  'blue-900': map.get(pallete.$ume-blue-palette, 900),
  'pink-500': $pink,
  'purple-500': $purple,
);

// Reassign color vars to semantic color scheme
$brand-primary: $blue !default;
$brand-secondary: $green !default;
$brand-success: $green !default;
$brand-info: $blue !default;
$brand-warning: $orange !default;
$brand-caution: $yellow !default;
$brand-danger: $red !default;
$brand-inverse: $gray-dark !default;
$brand-primary-dark: #0070a6 !default;

$border-color: map.get(pallete.$ume-grey-palette, 300);
$border-color-primary: $brand-primary;
$bg-highlight: $blue-lightest;

// Other colors
$logo-red: rgb(254, 0, 0);
$logo-blue: rgb(1, 176, 241);
$logo-dark-blue: rgb(31, 72, 124);
$mainGrey: #86888a;
$mainRed: #ec406d;
$mainBlue: #009ed8;
$blueBg: rgba(0, 158, 216, 0.04);
$blueBgDark: rgba(0, 158, 216, 0.1);
$premium-gold: #c7a450;
$notification-main-color: #1e88e5;

// plans color scheme
$std: $brand-success;
$std-bg: rgba(31, 159, 85, 0.1);
$plus: #8a017c;
$plus-bg: rgba(138, 1, 124, 0.1);
$premium: $premium-gold;
$premium-bg: rgba(199, 164, 80, 0.1);

$box-shadow-color: rgba(0, 0, 0, 0.25);
$base-shadow:
  0px 0px 2px rgba(0, 0, 0, 0.14),
  0px 2px 2px rgba(0, 0, 0, 0.12),
  0px 1px 3px rgba(0, 0, 0, 0.2);
$primary-shadow:
  0 0 12px 4px rgba(0, 176, 255, 0.14),
  0px 2px 2px rgba(0, 176, 255, 0.12),
  0px 1px 3px rgba(0, 176, 255, 0.2);

$font-size-base: 14px;
$fs-xl: 24px;
$fs-lg: 18px;
$fs-md: 16px;
$fs-sm: 12px;
$fs-xs: 10px;

$h1-font-size: 28px;
$h2-font-size: 24px;
$h3-font-size: 20px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: $font-size-base;

$font-weight-values: (
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900,
) !default;
$letter-spacing: (
  title: 0.25px,
  button: 0.5px,
  rest: 0,
) !default;

$border-width: 1px !default;
$border-radius: 15px !default;
$button-border-radius: 16px;
$ume-transition-duration: 0.2s;
$ume-transition-tf: cubic-bezier(0.4, 0, 0.2, 1);
$transition-base: all $ume-transition-duration $ume-transition-tf !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;
$transition-collapse-width: width 0.2s ease !default;

// Break Points
$FHD: 1920px;
$xxxl: 1600px;
$xxl: 1440px;
$xl: 1200px;
$lg_: 1024px;
$lg: 992px;
$md: 768px;
$sm: 576px;

// Base size
$content-gap: 16px;
$content-gap-half: math.div($content-gap, 2);
$content-gap-third: math.div($content-gap, 1.33333);
$content-gap-quoter: math.div($content-gap, 4);
$content-gap-md: $content-gap * 1.5; // 24
$content-gap-md-2: $content-gap-md * 2; // 48
$base-gap: $content-gap * 2;
$page-content-gap: 40px;
$page-content-gap-2: $page-content-gap * 2;

// Body
$body-bg: $gray-lightest-bg !default;
$body-color: $gray-darker !default;
$body-fs: $font-size-base !default;
$body-line-height: 1.4;
$body-font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Headings
$headings-margin-bottom: $content-gap;
$headings-font-family: $body-font-family;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: map.get($ume-color-palette, grey-900);

// Layout
$loader-height: 4px;
$tabs-height: 64px;
$header-height: 64px;
$header-height-calc: $header-height + $loader-height;
$header-min-height: 48px;
$header-min-height-calc: $header-min-height + $loader-height;
$header-logo-width: 48px;
$header-small-logo-width: 32px;
$navigation-height: 148px;
$menu-width: 220px;
$chart-padding: 100px;

// oversight-status
$icon-sm: $fs-lg;
$icon-regular: 24px;
$icon-xs: $font-size-base;

// tables
$th-bg: $gray-lighter-200 !default;
$th-color: $gray-darker !default;
$th-font-weight: map.get($font-weight-values, medium) !default;
$th-font-size: $fs-sm !default;
$th-letter-sp: map.get($letter-spacing, title) !default;
$th-height: 40px !default;

// z-index
$z-index-menu: 1099;
$z-index-popup: 1100;
