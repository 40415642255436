@use 'sass:color';
@use 'sass:map';
@use '../variables';
@use "../mixins/mixins";

.risk-value {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 12px;
  padding: 8px 20px;

  & > span {
    font-size: variables.$body-fs;
    font-weight: 500;
  }

  &.red,
  &.assess-cat-high {
    background-color: color.adjust(variables.$brand-danger, $alpha: -0.9);
    color: variables.$brand-danger;
  }

  &.yellow,
  &.assess-cat-medium {
    background-color: color.adjust(map.get(variables.$ume-color-palette, yellow-500), $alpha: -0.9);
    color: map.get(variables.$ume-color-palette, yellow-500);
  }

  &.green,
  &.assess-cat-low {
    background-color: color.adjust(variables.$brand-success, $alpha: -0.9);
    color: variables.$brand-success;
  }
}

.assessment-group_metrics {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item {
    margin: 2px;
    min-width: 32px;

    @include mixins.screen(variables.$xl) {
      margin: 0 variables.$content-gap-quoter;
    }

    & > button {
      color: variables.$gray-light;
    }
  }

  .name {
    color: variables.$gray-light;
    font-size: variables.$fs-sm;
    font-weight: 500;
    text-align: center;
    height: 24px;
    margin-bottom: variables.$content-gap-quoter;

    @include mixins.screen(variables.$xl) {
      font-size: variables.$body-fs;
    }
  }

  .value {
    text-align: center;
    padding: variables.$content-gap-quoter 0;

    &.big {
      font-size: variables.$fs-lg;
    }
  }
}

.assess-cat-low {
  background-color: color.adjust(variables.$brand-success, $alpha: -0.9);
  color: variables.$brand-success;
}

.assess-cat-medium {
  background-color: color.adjust(map.get(variables.$ume-color-palette, yellow-500), $alpha: -0.9);
  color: map.get(variables.$ume-color-palette, yellow-500);
}

.assess-cat-high {
  background-color: color.adjust(variables.$brand-danger, $alpha: -0.9);
  color: variables.$brand-danger;
}

.assess-cat-invalid {
  color: variables.$brand-inverse;
}
