@use '../../variables';
@use '../../mixins/mixins';

.mat-mdc-optgroup {
  &.no-opt-label {
    .mat-mdc-optgroup-label {
      display: none;
    }
  }
}

.mat-mdc-select-panel {
  &.panel-height-300 {
    min-height: 300px;
  }

  &.panel-height-400 {
    min-height: 400px;
  }

  &.panel-height-450 {
    min-height: 450px;
  }

  &.panel-width-xl {
    min-width: 300px;
  }

  &.oversight-status-panel {
    min-height: 460px;

    .mat-mdc-optgroup {
      .mat-mdc-optgroup-label {
        line-height: 2em;
        min-height: variables.$content-gap * 2;
      }
    }

    .mat-mdc-option {
      line-height: 2em;
      height: 2em;
    }
  }

  .mat-mdc-option {
    &.hidden-checkbox {
      mat-pseudo-checkbox {
        display: none;
      }
    }
  }

  .no-margin-icon {
    margin: 0 !important;
  }

  .icon-sm {
    @include mixins.mat-icon(variables.$font-size-base);
  }
}

mat-select-trigger {
  &.labels-wrapper {
    display: flex;
    gap: variables.$content-gap-quoter;
  }

  .no-margin-icon {
    margin: 0 !important;
  }

  .icon-sm {
    @include mixins.mat-icon(variables.$font-size-base);
  }
}
