@use 'sass:map';
@use 'sass:color';
@use '../../variables';

.mdc-tooltip {
  &[class^='ume-'],
  &[class*='ume-'] {
    font-size: 12px;
  }

  &.ume-tooltip-primary {
    background-color: map.get(variables.$ume-color-palette, 'blue-100') !important;
  }

  &.ume-tooltip-accent {
    background-color: color.adjust(variables.$brand-secondary, $alpha: -0.2);
  }

  &.ume-tooltip-success {
    background-color: color.adjust(variables.$brand-success, $alpha: -0.2);
  }

  &.ume-tooltip-warn {
    background-color: color.adjust(variables.$brand-warning, $alpha: -0.2);
  }
}
