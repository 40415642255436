@use '../../variables';
@use '../../mixins/mixins';

.mat-mdc-dialog-surface {
  padding: variables.$content-gap-md;
}

.mdc-dialog__title {
  margin: 0 !important;
  padding: 0 variables.$content-gap !important;

  &::before {
    height: 0 !important;
  }
}

.mdc-dialog__content {
  padding: variables.$content-gap-half variables.$content-gap !important;
  @include mixins.customScroll(6px, variables.$brand-primary, variables.$brand-primary);
}

.mat-mdc-dialog-container {
  position: relative;

  .close-btn {
    position: absolute;
    top: -18px;
    right: -18px;
    color: variables.$gray-light;
  }

  .extra-close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    color: variables.$gray-light;
  }
}
