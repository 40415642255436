@use 'sass:color';
@use '../../variables';

$initial-dark-field-color: rgba(255, 255, 255, 0.7);
$transparent-color: rgba(255, 255, 255, 0);

textarea.mat-mdc-input-element {
  line-height: 1.4em;
}

textarea.mat-mdc-input-element.mat-autosize {
  padding: 0;
}

.mat-mdc-input-element.text-center {
  text-align: center;
}

.mat-mdc-form-field {
  //min-width: 180px !important;

  &.mat-form-field-appearance-fill {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: #ffffff;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: #ffffff;
    }
  }

  .flex & + & {
    margin-top: 0;
  }

  &.full-width {
    width: 100%;
  }

  &.no-validation {
    .mat-mdc-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  &.no-label {
    .mat-mdc-form-field-infix {
      border-top-width: 0;
    }
  }

  &.warn-field {
    .mat-mdc-text-field-wrapper {
      background: linear-gradient(0deg, rgba(243, 199, 3, 0.1), rgba(243, 199, 3, 0.1)), #ffffff;
    }
  }

  &.danger-field {
    .mat-mdc-text-field-wrapper {
      background-color: color.adjust(variables.$brand-danger, $alpha: -0.9);
    }
  }

  &.highlighted {
    .mat-mdc-text-field-wrapper {
      background-color: variables.$blue-lightest;
    }
  }
}

.mat-mdc-form-field {
  &.no-sub-wrapper {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }
  }
}

@-webkit-keyframes webkit-autofill {
  0%,
  100% {
    -webkit-text-fill-color: #ffffff;
    background-color: $transparent-color;
  }
}
