@use "../variables";

label {
  font-weight: 400;
}

textarea {
  resize: none;
}

button {
  outline: none;

  &[disabled] {
    cursor: not-allowed;
  }

  &.link-like {
    background-color: transparent;
    border:           none;
    outline:          none;

    &[disabled] {
      color: variables.$gray-lighter;
    }
  }
}

.mat-mdc-radio-label,
.mat-checkbox-layout {
  margin-top:    4px;
  margin-bottom: 4px;
}

.mdc-button-base .mat-spinner {
  position: absolute;
  top:      calc(50% - 9px);;
  left:     calc(50% - 9px);
  margin:   0;
}

.buttons-wrap,
.buttons-row {
  display:         flex;
  flex-wrap:       wrap;
  justify-content: flex-end;

  button[class*="mat-button"] + a[class*="mat-button"] {
    margin-left: variables.$content-gap;
  }

  a[class*="mat-button"] + button[class*="mat-button"] {
    margin-left: variables.$content-gap;
  }

  button + button {
    margin-left: variables.$content-gap;
  }
}

.form-group {
  margin-bottom: variables.$content-gap-half;
}

.flex {
  .form-group-half {
    flex:  1 1 320px;
    width: 50%;
  }

  &.spaced {
    .form-group-half {
      flex:  1 1 320px;
      width: 48%;

      &:not(:first-child) {
        margin-left: variables.$content-gap;
      }
    }
  }
}

.phone {
  width:           100%;
  display:         flex;
  justify-content: flex-start;
  align-items:     center;

  mat-form-field {
    flex:        1 1 auto;
    margin-left: variables.$content-gap;

    &.phone-type {
      flex: 0 1 100px;
    }
  }

  .mat-mdc-form-field + .mat-mdc-form-field {
    margin-top: 0;
  }

  .icon {
    font-size:    1em;
    margin-right: 5px;
  }
}

.form-grid-row {
  display:               grid;
  grid-template-columns: minmax(280px, 1fr) minmax(280px, 1fr);
  grid-template-rows: min-content;
  column-gap:            16px;
  // justify-items: start | end | center | stretch;
  // align-items: start | end | center | stretch;

  &.align-items-start {
    align-items: start
  }

  &.fr-1 {
    grid-template-columns: minmax(280px, 1fr);
  }

  &.fr-1-1 {
    grid-template-columns: minmax(280px, 1fr) minmax(280px, 1fr);
  }

  &.fr-1-1-1 {
    grid-template-columns: minmax(220px, 1fr) minmax(220px, 1fr) minmax(220px, 1fr);
  }

  &.fr-1-1-1-1 {
    grid-template-columns: minmax(180px, 1fr) minmax(180px, 1fr) minmax(180px, 1fr) minmax(180px, 1fr);
  }

  &.fr-3-9 {
    grid-template-columns: minmax(220px, 3fr) minmax(220px, 9fr);
  }
}