@use 'sass:map';
@use '../variables';
@use '../mixins/mixins';

:root {
  --content-gap: #{variables.$content-gap};
  --content-gap-md-2: #{variables.$content-gap-md-2};
  --FHD: #{variables.$FHD};

  --loader-height: #{variables.$loader-height};
  --tabs-height: #{variables.$tabs-height};
  --header-height: #{variables.$header-height};
  --header-min-height: #{variables.$header-min-height};
  --nav-height: #{variables.$navigation-height};
  --header-calc-height: var(--loader-height) + var(--header-height); // 68
  --header-min-calc-height: var(--loader-height) + var(--header-min-height); // 52

  --lazy-table-min-height: calc(
    100vh - (var(--header-calc-height) + var(--tabs-height) + var(--content-gap))
  ); // -148

  --header-height-calc: var(--header-height) + var(--loader-height);
  --header-min-height-calc: var(--header-min-height) + var(--loader-height);
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: variables.$body-bg;
  color: variables.$body-color;
  font-family: variables.$body-font-family;
  font-size: variables.$body-fs;
  line-height: variables.$body-line-height;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;

  &.white {
    background-color: white;
  }
}

a {
  color: map.get(variables.$ume-color-palette, 'blue-600');

  &[disabled] {
    pointer-events: none;
  }
}

p {
  text-wrap: pretty;
}

p.text {
  margin-bottom: variables.$content-gap;
  text-wrap: pretty;
}

small {
  font-size: variables.$fs-sm;
  letter-spacing: 0.4px;
}

//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: variables.$headings-margin-bottom;
  font-family: variables.$headings-font-family;
  font-weight: variables.$headings-font-weight;
  line-height: variables.$headings-line-height;
  color: variables.$headings-color;
  text-wrap: balance;
}

h1,
.h1 {
  font-size: variables.$h1-font-size;
}

h2,
.h2 {
  font-size: variables.$h2-font-size;
}

h3,
.h3 {
  font-size: variables.$h3-font-size;
}

h4,
.h4 {
  font-size: variables.$h4-font-size;
}

h5,
.h5 {
  font-size: variables.$h5-font-size;
}

h6,
.h6 {
  font-size: variables.$h6-font-size;
}

// Main layout

#app-wrapper {
  grid-template-rows:
    [top-line] variables.$loader-height
    [top-line-start] variables.$header-height
    [top-line-end] calc(100vh - var(--header-height-calc))
    [bottom-line];

  .affix & {
    grid-template-rows:
      [top-line] variables.$loader-height
      [top-line-start] variables.$header-min-height
      [top-line-end] calc(100vh - var(--header-min-height-calc))
      [bottom-line];
  }
}

.base-content-grid {
  display: grid;
  grid-template-columns:
    [base-full-width-start breakout-left-start]
    minmax(variables.$content-gap-half, 1fr)
    [content-start breakout-right-start] min(
      100% - var(--content-gap),
      var(--FHD)
    ) [content-end breakout-left-end]
    minmax(variables.$content-gap-half, 1fr)
    [base-full-width-end breakout-right-end];
  grid-template-rows: auto;
  grid-column-gap: 0;

  @include mixins.screen(variables.$xl) {
    grid-template-columns:
      [base-full-width-start breakout-left-start]
      minmax(variables.$content-gap-md, 1fr)
      [content-start breakout-right-start] min(
        100% - var(--content-gap-md-2),
        var(--FHD)
      ) [content-end breakout-left-end]
      minmax(variables.$content-gap-md, 1fr)
      [base-full-width-end breakout-right-end];
    min-height: 100vh;
    min-height: 100svh;
  }
}

.base-content-grid > :not(.breakout, .base-full-width),
.base-full-width > :not(.breakout, .base-full-width) {
  grid-column: content;
}

.base-content-grid > .breakout {
  grid-column: breakout;
}

.base-content-grid > .base-full-width {
  display: grid;
  grid-column: base-full-width;
  grid-template-columns: inherit;
}

.tabs-content-grid {
  display: grid;
  grid-template-columns: [content-start] min(100%, var(--FHD)) [content-end];
  grid-template-rows: [top-line-start] variables.$tabs-height [top-line-end] 1fr [bottom-line];
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.tabs-content-grid > * {
  grid-column: content;
}

#ume-top {
  z-index: 101;
}

#logo {
  img {
    width: variables.$header-logo-width;
    height: variables.$header-logo-width;
  }
}

#page-content {
  position: relative;
}

.calcHeight {
  transition: height variables.$ume-transition-duration variables.$ume-transition-tf;
  will-change: height;

  // @include screen-max($xl) {
  //   &:not(.keep-height) {
  //     height:     inherit !important;
  //     min-height: auto;
  //   }
  // }
}

// affix header
.affix {
  #logo {
    img {
      width: variables.$header-small-logo-width;
      height: variables.$header-small-logo-width;
    }
  }

  .profile-menu-button {
    line-height: 24px;
  }
}

.company-details {
  &__img {
    height: 40px;
    width: 40px;
    will-change: height, width;

    .affix & {
      height: 32px;
      width: 32px;
    }
  }

  .no-user-icon {
    .affix & {
      font-size: 32px !important;
      width: 32px !important;
      height: 32px !important;
    }
  }
}

.app-menu-ul {
  .link {
    text-transform: uppercase;
    will-change: font-size, line-height;
  }

  .affix & {
    .link {
      font-size: variables.$fs-sm;
      line-height: 31px;
    }
  }
}

// page content
article.article {
  display: flex;
  flex-direction: column;
  height: min(100%, calc(100vh - (var(--header-height-calc) + var(--tabs-height))));

  .affix & {
    height: min(100%, calc(100vh - (var(--header-min-height-calc) + var(--tabs-height))));
  }

  & > main {
    position: relative;
    display: flex;
    flex: 1 1 auto;

    .content {
      width: 100%;
    }
  }

  & > .mat-mdc-mini-fab {
    position: absolute;
    left: -(variables.$page-content-gap);
    top: variables.$content-gap-md;
  }
}

.article-header {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: variables.$content-gap-half;
  padding-bottom: variables.$content-gap-half;

  .mat-checkbox + button,
  button + .mat-checkbox {
    margin-left: variables.$content-gap;
  }

  button + button,
  & > [class*='mat-mdc-button'] + [class*='mat-mdc-button'] {
    margin-left: variables.$content-gap;
  }

  .mat-mdc-form-field + button,
  button + .mat-mdc-form-field {
    margin-left: variables.$content-gap;
  }

  .head-left {
    display: flex;
  }
}

.shown {
  display: block !important;
  visibility: visible;
}

.cdk-overlay-container {
  z-index: variables.$z-index-menu;
}

svg.peity {
  margin: 0 5px -2px 0;
}

.popover {
  max-width: 800px;
}

.add-user {
  width: 400px;
}

nav .mat-expansion-panel-body {
  padding: 0 !important;
}

nav .mat-expansion-indicator::after {
  color: #dfe4ed !important;
}

nav .mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
}

nav .mat-expansion-indicator::after {
  padding: 2px !important;
}

.no-padding mat-dialog-container {
  padding: 0;
}

.divider {
  width: 100%;
  background: #eeeeee;
  display: block;
  height: 1px;
}

.mat-menu-item {
  height: 38px !important;
  line-height: 38px !important;
}

$tooltip-color: #333333;

.ol-tooltip {
  padding: 5px;
  background: $tooltip-color;
  color: #ffffff;
  border-radius: 3px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 5px);
    bottom: -5px;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: $tooltip-color transparent transparent transparent;
  }
}

.mat-slider-thumb-label {
  top: -45px !important;
  width: 35px !important;
  height: 35px !important;
}

time {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

ul.ume-ul {
  padding-left: variables.$content-gap * 2;
  margin-bottom: variables.$content-gap-half;

  li {
    &::before {
      content: '\2022';
      color: map.get(variables.$ume-color-palette, blue-300);
      font-size: variables.$fs-md;
      font-weight: bold;
      display: inline-block;
      width: 0.8em;
      margin-left: -0.8em;
    }

    & + li {
      margin-top: variables.$content-gap-half;
    }
  }
}

ol.alphabet-list {
  list-style: lower-alpha;
  padding-left: variables.$content-gap * 2;
  margin-bottom: variables.$content-gap-half;

  li {
    & + li {
      margin-top: variables.$content-gap-half;
    }
  }
}

.data-popover {
  cursor: help;
  position: relative;
  display: inline-block;
  text-decoration: underline;

  p + p {
    margin-top: variables.$content-gap-half;
  }

  & > .data-popover-popup {
    background-color: map.get(variables.$ume-color-palette, 'blue-100');
    box-shadow: variables.$base-shadow;
    border-radius: 8px;
    color: variables.$body-color;
    cursor: auto;
    position: absolute;
    left: -50px;
    padding: variables.$content-gap;
    min-width: 420px;
    opacity: 0;
    visibility: hidden;
    z-index: 101;
    transition: opacity variables.$ume-transition-duration variables.$ume-transition-tf;

    &.left-top {
      top: initial;
      bottom: 0;
      left: 100%;
    }
  }

  &:hover,
  &:focus {
    & > .data-popover-popup {
      visibility: visible;
      opacity: 1;
    }
  }
}
