@use "../variables";

.table {
  width: 100%;

  & > thead {
    background-color: variables.$th-bg;
  }

  & > tbody > tr > td,
  & > tbody > tr > th,
  & > tfoot > tr > td,
  & > tfoot > tr > th,
  & > thead > tr > td,
  & > thead > tr > th {
    padding: 8px;
  }

  & > tbody > tr > td,
  & > tbody > tr > th {
    vertical-align: middle;
  }

  & > thead th {
    color:          variables.$th-color;
    font-weight:    variables.$th-font-weight;
    font-size:      variables.$th-font-size;
    letter-spacing: variables.$th-letter-sp;
    text-align:     left;
    vertical-align: middle;
    min-height:     variables.$th-height;
  }

  & > tbody > tr {
    &:hover {
      background-color: variables.$gray-lightest-bg;
    }
  }

  &.separate {
    border-collapse: separate;
    border-spacing:  1px;
  }
}

table {
  .table-striped {
    & > tbody > tr {
      background-color: white;

      &:nth-child(odd) {
        background-color: variables.$gray-lightest;

        &:hover {
          background-color: variables.$gray-lightest-bg;
        }
      }
    }
  }

  .date-cell {
    font-size: 14px;
  }
}

.table-wrap {
  table {
    font-size:     variables.$font-size-base;
    margin-bottom: variables.$content-gap-quoter;

    & > tbody > tr > td {
      vertical-align: middle;
    }
  }

  &.condensed {
    table {
      & > tbody > tr > td {
        padding: variables.$content-gap-quoter variables.$content-gap-half;
      }

      & > thead > tr > th {
        padding-left:  variables.$content-gap-half;
        padding-right: variables.$content-gap-half;
      }
    }
  }
}
