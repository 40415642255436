@use '../variables';

.company-oversight-status {
  display: inline-flex;
  align-items: center;
  font-size: variables.$body-fs;
  min-width: 70px;
  padding: variables.$content-gap-half 0;

  & > span {
    border-radius: 50%;
    display: inline-block;
    width: variables.$content-gap-half;
    height: variables.$content-gap-half;
    margin-right: variables.$content-gap-half;
  }
}
