@use 'ghost-animation';

.ghost-line {
  width: 100%;
  height: 18px;
  @include ghost-animation.background-gradient;
  @include ghost-animation.animate-ghost-line;

  & + & {
    margin-top: 8px;
  }
}

.ghost-block {
  height: 36px;
  @include ghost-animation.background-gradient;
  @include ghost-animation.animate-ghost-line;
}
