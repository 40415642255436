@use '../../variables';

.mat-icon {
  &.mat-icon-sm {
    font-size: variables.$icon-sm;
    width: variables.$icon-sm;
    height: variables.$icon-sm;

    & + .mat-icon {
      margin-left: variables.$content-gap-half;
    }
  }
}

.mat-mdc-button > .mat-icon {
  height: 1.5rem !important;
  width: 1.5rem !important;
  font-size: 1.5rem !important;
}
