@use 'sass:map';
@use '../../variables';
@use '../../mixins/mixins';

.mat-mdc-tab-group {
  &.ume-vertical-tabs {
    flex-direction: row;

    &.onboarding-vertical-tabs {
      .mat-mdc-tab-label {
        height: auto;
        padding: variables.$content-gap-quoter;
        opacity: 0.75;

        &.mat-mdc-tab-label-active {
          background-color: variables.$gray-lighter;
          opacity: 0.9;
        }
      }
    }

    &.tab-label-left {
      .mat-mdc-tab-label-content {
        justify-content: flex-start;
      }
    }

    .mat-mdc-tab-header {
      border-bottom: none;
      flex: 0 1 15%;
      min-width: 200px;
    }

    .mat-mdc-tab-body-wrapper {
      flex: 1 1 85%;
    }

    .mat-mdc-tab-header-pagination {
      display: none !important;
    }

    .mat-mdc-tab-label-container {
      height: 100%;
      overflow-y: auto;
      @include mixins.customScroll(6px, variables.$brand-primary, variables.$brand-primary);
    }

    .mat-mdc-tab-list {
      width: 100%;
    }

    .mat-ink-bar {
      display: none;
    }

    .mat-mdc-tab-label-content {
      width: 100%;
    }

    .mat-mdc-tab-labels {
      flex-direction: column;
    }

    .mat-mdc-tab-label {
      opacity: 0.75;

      &.mat-mdc-tab-label-active {
        background-color: map.get(variables.$ume-color-palette, 'blue-100');
        opacity: 0.9;
      }
    }

    .mat-ink-bar {
      height: 100%;
      left: 98% !important;
    }
  }
}

.mat-mdc-tab-link-container {
  flex-grow: 0 !important;

  .mat-mdc-tab-link {
    padding: 0 variables.$content-gap;
    font-weight: map.get(variables.$font-weight-values, medium);

    .mdc-tab__text-label {
      color: variables.$black;
      text-transform: uppercase;
    }
  }
}

.mat-mdc-tab-body-content {
  @include mixins.customScroll(6px, variables.$brand-primary, variables.$brand-primary);
}

.screen-menu {
  &.mat-mdc-tab-header {
    .mat-mdc-tab-link {
      text-transform: uppercase;
    }

    .mat-mdc-tab-label-active {
      color: variables.$brand-primary;
      background-color: map.get(variables.$ume-color-palette, 'blue-100');
    }
  }
}
