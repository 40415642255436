@use '../variables';
@use '../mixins/mixins';

.loading-overlay {
  position: absolute;
  inset: 0;
  margin: 0 -30px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 3;

  @include mixins.screen(variables.$xl) {
    margin: 0 -40px;
    height: 100%;
  }

  ume-loader {
    position: absolute;
    left: 50%;
    top: 200px;
    transform: translateX(-50%);
  }
}
