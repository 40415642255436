@use '../../variables';

.mat-mdc-menu-panel {
  width: 360px;

  &.custom-mat-menu {
    max-width: 360px !important;
    width: 340px;
    padding: variables.$content-gap-half variables.$content-gap;
  }

  &.fit-size-menu {
    width: fit-content;
    padding: variables.$content-gap-half variables.$content-gap-quoter;
  }

  &.sm-size-menu {
    width: 240px;
    padding: variables.$content-gap-half variables.$content-gap;
  }

  &.mid-size-menu {
    max-width: 300px !important;
    width: max-content;
    padding: variables.$content-gap-half variables.$content-gap;
  }

  &.mat-menu-xl {
    width: 400px;
    max-width: 400px !important;
    padding: variables.$content-gap-half variables.$content-gap;

    .mdc-button {
      display: block !important;
    }
  }

  &.mat-menu-xxl {
    width: 650px;
    max-width: 700px !important;
    padding: variables.$content-gap-half variables.$content-gap;

    .mdc-button {
      display: block !important;
    }
  }
}

a.mat-menu-item {
  &:focus {
    outline: none;
  }
}

.mat-mdc-option {
  font-size: variables.$fs-md;
}

.mat-mdc-autocomplete-panel {
  &.mat-menu-xl {
    width: 400px;
    max-width: 400px !important;
    padding: variables.$content-gap-half variables.$content-gap;

    .mdc-button {
      display: block !important;
    }
  }
}
