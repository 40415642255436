@use 'sass:map';
@use 'material-design-icons-iconfont/src/variables';
@use 'material-design-icons-iconfont/src/mixins';

$font-path: '/assets/fonts/';
$material-design-icons-class-icons-generate-codepoints: true !default;
$material-design-icons-class-icons-prefix: '' !default;
// font mixin
@mixin roboto-font($folder, $variant, $type, $weight, $style) {
  $font-full-path: '#{$font-path}#{$folder}#{$variant}';

  @font-face {
    font-family: '#{$variant}';
    src:
      url('#{$font-full-path}-#{$type}.woff2') format('woff2'),
      url('#{$font-full-path}-#{$type}.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
  }

  @font-face {
    font-family: '#{$variant}-#{$type}';
    src:
      url('#{$font-full-path}-#{$type}.woff2') format('woff2'),
      url('#{$font-full-path}-#{$type}.woff') format('woff');
  }
}

/*
// Roboto Fonts
*/
@include roboto-font('roboto/', 'Roboto', 'Light', 300, normal);
@include roboto-font('roboto/', 'Roboto', 'Regular', 400, normal);
@include roboto-font('roboto/', 'Roboto', 'RegularItalic', 400, italic);
@include roboto-font('roboto/', 'Roboto', 'Medium', 500, normal);
//@include roboto-font('roboto/', 'Roboto', 'MediumItalic', 500, italic);
@include roboto-font('roboto/', 'Roboto', 'Bold', 700, normal);
//@include roboto-font("roboto/", "Roboto", "BoldItalic", 700, italic);

//$roboto-font-path:                          "~roboto-fontface/fonts";
//@import "../../../node_modules/roboto-fontface/css/roboto/sass/roboto-fontface-light"; // 300
//@import "../../../node_modules/roboto-fontface/css/roboto/sass/roboto-fontface-regular"; // 400
//@import "../../../node_modules/roboto-fontface/css/roboto/sass/roboto-fontface-regular-italic"; // 400 i
//@import "../../../node_modules/roboto-fontface/css/roboto/sass/roboto-fontface-medium"; // 500
//@import "../../../node_modules/roboto-fontface/css/roboto/sass/roboto-fontface-medium-italic"; // 500 i
//@import "../../../node_modules/roboto-fontface/css/roboto/sass/roboto-fontface-bold"; // 700
//@import "../../../node_modules/roboto-fontface/css/roboto/sass/roboto-fontface-bold-italic"; // 700 i

/*
// Angular material icons
// As log as we hold fonts locally we need to update Material Icons manually from
// $material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";
*/
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url($font-path + 'MaterialIcons-Regular.eot');
  src:
    local('☺'),
    url($font-path + 'MaterialIcons-Regular.woff2') format('woff2'),
    url($font-path + 'MaterialIcons-Regular.woff') format('woff'),
    url($font-path + 'MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';

  @if $material-design-icons-class-icons-generate-codepoints {
    @each $name, $codepoint in variables.$material-icons-codepoints {
      $codepoint: map.get(variables.$material-icons-codepoints, $name);

      $class-name: $material-design-icons-class-icons-prefix + $name;
      $class-name: mixins.normalize-class-name($class-name);

      &.#{$class-name} {
        &:before {
          content: mixins.material-icons-content($codepoint);
        }
      }
    }
  }
}
