@use "sass:map";
@use '../variables';
@use '../mixins/mixins';

.hidden {
  display: none !important;
}

.no-pointer-events {
  cursor: auto;
  pointer-events: none;
}

.pointer-events {
  cursor: auto;
  pointer-events: all;
}

.ume-scroll {
  @include mixins.customScroll(6px, variables.$brand-primary, variables.$brand-primary);
}

.img-round {
  border-radius: 50%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.h-100 {
  height: 100%;
}

.m {
  &-t {
    margin-top: variables.$content-gap;

    &-0 {
      margin-bottom: 0;
    }
  }

  &-b {
    margin-bottom: variables.$content-gap;

    &-0 {
      margin-bottom: 0;
    }

    &-half {
      margin-bottom: variables.$content-gap-half;
    }
  }

  &-l {
    margin-left: variables.$content-gap;
  }

  &-r {
    margin-right: variables.$content-gap;
  }
}

.n-m {
  margin: 0;
}

.p {
  &-t {
    padding-top: variables.$content-gap;
  }

  &-b {
    padding-bottom: variables.$content-gap;
  }

  &-l {
    padding-left: variables.$content-gap;
  }

  &-r {
    padding-right: variables.$content-gap;
  }
}

.n-p {
  padding: 0;
}

.relative {
  position: relative;
}

// flex patterns
.flex {
  display: flex;
  flex-flow: row wrap;

  &.flex-column {
    flex-direction: column;
    flex-flow: column;
  }

  &.nowrap,
  &.flex-nowrap {
    flex-wrap: nowrap;
  }

  &.grow-0,
  & .grow-0 {
    flex-grow: 0;
  }

  &.grow-1,
  & .grow-1 {
    flex-grow: 1;
  }

  &.expand-center {
    justify-content: space-around;
  }

  &.expand {
    align-content: space-between;
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }

  &.justify-start {
    justify-content: start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.flex-gap {
    gap: variables.$content-gap;
  }

  &.flex-half-gap {
    gap: variables.$content-gap-half;
  }

  &.flex-gap-md {
    gap: variables.$content-gap-md;
  }

  &.flex-double-gap {
    gap: variables.$content-gap * 2;
  }
}

.flex-align-center {
  align-items: center;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

// text
.font-bold {
  font-weight: map.get(variables.$font-weight-values, bold);
}

.font-medium {
  font-weight: map.get(variables.$font-weight-values, medium);
}

.font-regular {
  font-weight: map.get(variables.$font-weight-values, regular);
}

.nowrap {
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.link-like {
  color: variables.$brand-primary-dark;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-skip-ink: auto;

  &:active,
  &:hover {
    outline: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// Alignment
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-v-center {
  vertical-align: middle;
}

// Transformation
.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.first-letter-upperCase {
  &:first-letter {
    text-transform: capitalize;
    display: inline-block;
  }
}

// Contextual colors

.red {
  color: variables.$red;
}

.green {
  color: variables.$green;
}

.yellow {
  color: variables.$yellow;
}

.gray {
  color: variables.$gray;
}

.blue {
  color: variables.$mainBlue;
}

.black {
  color: variables.$black;
}

.pink {
  color: variables.$pink-light;
}

.info {
  color: variables.$brand-primary;
}

.ok {
  color: variables.$brand-success !important;
}

.warn {
  color: variables.$brand-warning !important;
}

.bad {
  color: variables.$brand-danger !important;
}

.default {
  color: variables.$gray-light;
}

.text-default {
  color: variables.$body-color;
}

.text-white {
  color: white;
}

.text-muted {
  color: variables.$gray-lighter;
}

.text-primary {
  color: variables.$brand-primary-dark;
}

.text-success {
  color: variables.$brand-success;
}

.text-info {
  color: variables.$brand-primary;
}

.text-warning {
  color: variables.$brand-warning;
}

.text-caution {
  color: variables.$brand-caution;
}

.text-danger {
  color: variables.$brand-danger;
}

.text-purple {
  color: variables.$purple;
}

// Contextual backgrounds
.bg-white {
  background-color: white;
}

.bg-default {
  background-color: variables.$gray-light;
}

.bg-primary {
  color: #ffffff;
  background-color: variables.$brand-primary;
}

.bg-success {
  background-color: variables.$brand-success;
}

.bg-info {
  background-color: variables.$brand-primary;
}

.bg-warning {
  background-color: variables.$brand-warning;
}

.bg-caution {
  background-color: variables.$brand-caution;
}

.bg-danger {
  background-color: variables.$brand-danger;
}

// colors
.dark {
  background-color: #eeeeee;
}

.scoring {
  color: #ffffff;
}

.bg-info {
  background-color: variables.$brand-primary;
}

.bg-ok {
  background-color: variables.$brand-success;
}

.bg-warn {
  background-color: variables.$yellow;
}

.bg-bad {
  background-color: variables.$brand-danger;
}

.LOW {
  color: variables.$brand-success;
}

.HIGH {
  color: variables.$brand-danger;
}

.MEDIUM {
  color: map.get(variables.$ume-color-palette, yellow-500);
}

.mat-elevation-z00 {
  box-shadow: none;
}

.no-width-limit {
  max-width: none !important;
  width: auto;
}

.blue-highlight {
  color: variables.$notification-main-color;
  font-weight: 500;
}

// Default browser styles for inner HTML from the backend
.default-styles * {
  all: revert;
}

.default-padding * {
  padding: revert;
}

.default-margin * {
  margin: revert;
}

// @media queries
@include mixins.screen-max(variables.$md) {
  .hide-upTo-md {
    display: none;
  }
}

@include mixins.screen-max(variables.$lg) {
  .hide-upTo-lg {
    display: none;
  }
}

@include mixins.screen-max(variables.$lg_) {
  .hide-upTo-lg_ {
    display: none;
  }
}

@include mixins.screen-max(variables.$xl) {
  .hide-upTo-xl {
    display: none;
  }
}

@include mixins.screen-max(variables.$xxl) {
  .hide-upTo-xxl {
    display: none;
  }
}

@include mixins.screen-max(variables.$xxxl) {
  .hide-upTo-xxxl {
    display: none;
  }
}

@include mixins.screen(variables.$md) {
  .hide-from-md {
    display: none;
  }
}

@include mixins.screen(variables.$lg) {
  .hide-from-lg {
    display: none;
  }
}

@include mixins.screen(variables.$lg_) {
  .hide-from-lg_ {
    display: none;
  }
}

@include mixins.screen(variables.$xl) {
  .hide-from-xl {
    display: none;
  }

  .s-title-hide-above-1200 {
    display: none;
  }
}

@include mixins.screen(variables.$xxl) {
  .hide-from-xxl {
    display: none;
  }
}

@include mixins.screen(variables.$xxxl) {
  .hide-from-xxxl {
    display: none;
  }

  .s-title-hide-above-1600 {
    display: none;
  }
}
